/** 测试环境用户 */
export const ENV_TEST_USER_ID_LIST = {
    checkProduct: [],
    ratingProduct: []
}
/** 正式环境用户 */
export const ENV_PRO_USER_ID_LIST = {
    checkProduct: [
        45, //
    ],
    ratingProduct: [
        31, //
        36, // 吴燕
    ]
}

/** 跳转拍品审核数据列表用户IDList */
export const CHECK_PRODUCT_BY_USER_ID_LIST_OBJ = process.env.NODE_ENV === 'dev' ? ENV_TEST_USER_ID_LIST : ENV_PRO_USER_ID_LIST

/** 有那些路由不需要菜单 */
export const ROUTE_PATH_NOT_MENU_LIST = [
    '/checkAuctionProduct'
]